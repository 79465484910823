#repos{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  
#repos  .dis {
    width: 310px;
    height: 110px;
    margin: 10px 5px;
    display: flex;
    border-radius: 5px;
    border-color: #b5b5ba;
    border-width: 1px;
    border-style: solid;
  }
#repos .dis:hover{
    cursor: pointer;
    scale: 1.01;
    border-color: black;
}
  
#repos  .dis .data {
    margin: 10px;
    width: 310px;
    height: 90px;
    position: relative;
    border-color: #b5b5ba;
  }
  
#repos  .dis .data .top-down {
    font-size: 13px;
    color: #8e8e90;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
  }
  
#repos  .dis .data .visible {
    border-radius: 12px;
    border-color: #b5b5ba;
    border-width: 1px;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bolder;
    color: #97979b;
    padding: 1px 5px 1px 5px;
  }
  
#repos .top-right {
    position: absolute;
    top: 0;
    right: 0;
    margin: 2px;
  }
  
#repos  .dis .data .define {
    color: grey;
    font-size: 12px;
  }