#nav .nav-item{
  font-size: large;
  margin-right: 8px;
}

#nav .navbar{
  background-color: #2f2626;
  color:white;
}

/* styles.css */

