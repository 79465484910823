/* ProgressBar.css */
.progress-bar-container {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }
  
  .progress-bar-fill {
    height: 100%;
    transition: width 0.35s ease-in-out;
  }
  